<template>
    <div>
        <div id="top-banner">
            <base-banner v-if="details" :img="details.banners" height="half"></base-banner>
        </div>
        <div v-if="details" class="container py-5">
            <div class="row">
                <div class="col-12">
<!--                    <div @click="$router.go(-1)">-->
                    <div @click="goBack">
                        <img src="../../../assets/web/course-offered/greeen-arrow.png" class="icon-md" alt="">
                    </div>
                    <h2 class="font-lato-bold fs-lg-9 mb-4 mt-3">
                        {{ details.course_name }}
                    </h2>
                    <div class="pb-4 bb-1 mb-4">
                        <p v-if="details.applicants.length===0" class="p-2 bg-4">No Users added. Please add users by
                            clicking below button.</p>
                        <user-added-item v-else @loadDetails="loadDetails"
                                         :details="details" @editClicked="setEdit"
                                         class="mb-3 w-lg-80"/>
                        <btn v-if="details.application_status!=='Application Closed'
                                    && details.payment_status !== 'Transaction Completed'
                                    && details.is_online_payment"
                             @click="$refs.addUserFormModal.show()" text="Add User" icon="fa fa-user-plus"
                             icon-alignment="left" class="px-5 lego-btn-add-user"></btn>
                    </div>

                </div>
                <div class="col-12">
                    <div class="mb-2 fs--1">
                        <span>Name of Organization</span> :
                        <span class="font-lato-bold">{{ details.organization_name }}</span>
                    </div>
                     <div class="mb-2">
                        <span class="label-min-w">Seat Applied</span> :
                        <span class="ml-3">{{ details.seats_applied }} Students</span>
                    </div>
                    <div class="mb-2">
                        <span class="label-min-w">Seat Filled</span> :
                        <span class="ml-3">{{ details.seats_status }} Students</span>
                    </div>
                    <div class="mb-2">
                        <span class="label-min-w">Location</span> :
                        <span class="ml-3">{{ details.location }}</span>
                    </div>
                    <div class="mb-2">
                        <span class="label-min-w">Duration</span> :
                        <span class="ml-3">{{ details.duration }} {{ details.duration_unit }}</span>
                    </div>
                    <div class="mb-2">
                        <span class="label-min-w">Date</span> :
                        <span class="ml-3">{{ details.duration_formatted }}</span>
                    </div>
                    <div class="mb-2">
                        <span class="label-min-w">Tuition Fee (Rate/Person)</span> :
                        <div class="font-lato-semi-bold d-inline-block fs-lg-2 ml-3">
                            <span>&#x20B9;</span>
                            <span>{{ details.course_fee }}</span>
                        </div>
                        /-
                    </div>
                    <div class="mb-2">
                        <span class="label-min-w">Tax
                            <span v-if="details.tax_percentage!==0">({{details.tax_percentage}}% Rate/Person)</span>
                        </span> :
                        <div class="font-lato-semi-bold d-inline-block fs-lg-2 ml-3">
                            <span>&#x20B9;</span>
                            <span>{{ details.course_charges.total_percentage }}</span>
                        </div>
                        /-
                    </div>
                    <div class="mb-2">
                        <span class="label-min-w">Total Amount including all Applicants</span> :
                        <div class="font-lato-semi-bold d-inline-block fs-lg-2 ml-3">
                            <span>&#x20B9;</span>
                            <span>{{ details.total_include_applicants }}</span>
                        </div>
                        /-
                    </div>
                    <div class="mb-2">
                        <span class="label-min-w">Total Amount Including Tax</span> :
                        <div class="font-lato-semi-bold d-inline-block fs-lg-2 ml-3">
                            <span>&#x20B9;</span>
                            <span>{{ details.total_amount_include_applicants }}</span>
                        </div>
                        /-
                    </div>
                </div>
                <div class="col-12 col-lg-8 mt-4">
                    <simple-table :fields="incidentFields" :show-border="true" class="c-simple-table c-b-1"
                                  :data="details.course_charges.charges"></simple-table>
                    <btn v-if="details.application_status!=='Application Closed' &&
                    details.payment_status === 'Payment Pending' && details.is_online_payment"
                         @click="paymentClicked" text="PROCEED TO PAYMENT" design="basic-b"
                         border-radius="0" class="mt-5 px-3 fs--1 font-lato-medium"></btn>
                    <div class="fs-lg-1 mt-5 mb-4">
                        <div class="font-lato-medium" v-if="details.payment_status === 'Transaction Completed'">
                            <div class="fl-te-c fl-a-c mb-4 p-3 border-primary-dashed">
                                <div class="font-lato-bold fs-lg-3">Your Reference ID :
                                    <span ref="referenceId">{{ details.reference_id }}</span>
                                </div>
                                <div class="cursor-pointer" @click="copyReferenceId">
                                    <img src="../../../assets/web/course-offered/copy.png" class="icon-sm ml-3" alt="">
                                    <span class="ml-2 fs-lg-2 font-lato-medium">Copy ID</span>
                                </div>
                            </div>
                            <span class="text-danger font-lato-medium">Note : </span>
                            Your payment was successful, Please Keep this Reference ID
                            for further queries related to the payment.
                        </div>
                        <div class="font-lato-medium" v-if="details.payment_status === 'Transaction Processing'
                            || details.payment_status === 'Transaction Failed'
                            || details.payment_status === 'Transaction Pending'
                            || details.payment_status === 'Transaction Incomplete or Cancelled'
                             || details.payment_status === 'Transaction Timeout'">
                            <div class="fl-te-c fl-a-c p-3 border-primary-dashed">
                                <div class="font-lato-bold fs-lg-3">Your Reference ID :
                                    <span ref="referenceId">{{ details.reference_id }}</span>
                                </div>
                                <div class="cursor-pointer" @click="copyReferenceId">
                                    <img src="../../../assets/web/course-offered/copy.png" class="icon-sm ml-3" alt="">
                                    <span class="ml-2 fs-lg-2 font-lato-medium">Copy ID</span>
                                </div>
                            </div>
                            <br/>
                            <span class="text-danger font-lato-medium">Note : </span>
                            <span v-if="details.payment_status === 'Transaction Processing'">
                                Your payment was initiated, But could not complete the payment.
                            </span>
                            <span v-if="details.payment_status === 'Transaction Failed'">
                                Your payment was failed.
                            </span>
                            You can use this Reference ID for further queries related to the payment or
                            <a class="text-primary" @click="paymentClicked" href="#">
                                click here to re-initiate the payment
                            </a>.
                        </div>
                        <div v-if="!details.is_online_payment && details.payment_status==='Payment Pending'"
                             class="font-lato-medium">
                            <div class="fl-te-c fl-a-c p-3 border-primary-dashed">
                                <div class="font-lato-bold fs-lg-3">Your Reference ID :
                                    <span ref="referenceId">{{ details.reference_id }}</span>
                                </div>
                                <div class="cursor-pointer" @click="copyReferenceId">
                                    <img src="../../../assets/web/course-offered/copy.png" class="icon-sm ml-3" alt="">
                                    <span class="ml-2 fs-lg-2 font-lato-medium">Copy ID</span>
                                </div>
                            </div>
                            <br/>
                            <span class="text-danger font-lato-medium">Note : </span>
                            <span>
                                Your have registered to the Course using Manual Payment method.
                                Your booking will be completed only when the payment is received.
                            </span>
                            You can use this Reference ID for further queries related to the payment or
                            <a class="text-primary" @click="paymentClicked" href="#">
                                click here to change the payment method
                            </a>.
                            <div class="col-12">
                                <div class="p-3 mt-4 bg-4 fl-te-c">
                                    <div class="">
                                        <h4 class="text-primary fs-lg-1 font-lato-bold">TRAVEL ALLOWANCE</h4>
                                        <p class="mb-0 fs-lg--1">
                                            Note:If more than 5 students, travel allowance free from position | Enquiry
                                            Number: +91
                                            9876 567 999
                                        </p>
                                    </div>
                                    <img src="../../../assets/web/course-offered/travel.png" class="icon-md" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        Modals-->
        <modal class="c-modal no-close header-primary" :no-close-on-backdrop="true" title="User Information"
               ref="addUserFormModal" width="50r" header-color="">
            <user-information-form :details="details" @close="$refs.addUserFormModal.close()"
                                   @success="formSuccess"></user-information-form>
        </modal>
        <modal class="c-modal no-close header-primary" :no-close-on-backdrop="true" title="User Information"
               ref="editUserFormModal" width="50r" header-color="">
            <user-information-form :details="details" :editingItem="editingItem"
                                   @close="$refs.editUserFormModal.close()"
                                   @success="formSuccess"></user-information-form>
        </modal>
    </div>
</template>

<script>
import BaseBanner from '../../../components/ui/BaseBanner';
import UserInformationForm from './UserInformationForm';
import UserAddedItem from '../../../views/payment/organization/UserAddedItem';
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'CourseApplyPage',
    components: { UserAddedItem, UserInformationForm, BaseBanner },
    data () {
        return {
            details: {
                applicants: []
            },
            loading: false,
            id: this.$route.params.id,
            course_id: this.$route.params.course_id,
            incidentFields: [
                {
                    name: 'charge_type',
                    title: 'Particulars'
                },
                {
                    name: 'days',
                    title: 'Days'
                },
                {
                    name: 'rate',
                    title: 'Rate'
                },
                {
                    name: 'rate_per_person',
                    title: 'Rate/Person'
                }
            ],
            editingItem: null
        };
    },
    beforeMount () {
        this.loadDetails();
    },
    methods: {
        goBack () {
            if (this.$route.hash) {
                this.$router.push('/course-offered/' + this.course_id + '/details/' + this.$route.hash);
            } else {
                this.$router.push('/course-offered/' + this.course_id + '/details/');
            }
        },
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.website.course.coursesApplyDetails, { id: this.id });
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Sorry, Permission denied to the requested page', 'Permission Denied', {
                    type: 'danger'
                });
                await this.$router.push('/course-offered/');
            } else {
                if (json.error === false) {
                    this.details = json.data;
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.loading = false;
        },
        paymentClicked () {
            if (this.details.applicants.length === 0) {
                this.$notify('Add at least one participant to continue to the payment', 'Message', {
                    type: 'danger'
                });
            } else {
                const data = {
                    course_register_id: this.id,
                    course_fee: this.details.course_fee
                };
                const queryParams = Object.entries(data)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&');
                const baseUrl = process.env.VUE_APP_BASE_URL;
                window.location.href = `${baseUrl}/api/payment/initiate-transaction/?${queryParams}`;
            }
        },
        copyReferenceId () {
            const textToCopy = this.$refs.referenceId.innerText;
            const textarea = document.createElement('textarea');
            textarea.value = textToCopy;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            this.$notify('Copied', '', { type: 'warning' });
        },
        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editUserFormModal.show();
        },
        formSuccess (response) {
            this.details.applicants = response.data.applicants;
            this.details.seats_applied = response.data.seats_applied;
            this.details.total_include_applicants = response.data.total_include_applicants;
            this.details.total_amount_include_applicants = response.data.total_amount_include_applicants;
            this.$refs.addUserFormModal.close();
            this.$refs.editUserFormModal.close();
        },
        closeModal () {
            this.$refs.coursePaymentModal.close();
            this.loadDetails();
        }
    }
};
</script>

<style scoped>
.label-min-w {
    display: inline-block;
    min-width: 5rem;
}

.border-primary-dashed {
    border: 1px dashed var(--color-primary);
    border-radius: var(--round-2);
}
</style>
