<template>
    <b-form ref="form" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">
        <div class="row">
            <div class="col-12">
                <validated-input label="Name" name="Name" v-model="model.name"
                                 :rules="{required: true, max:80}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-date-picker value-type="YYYY-MM-DD" format="DD-MM-YYYY" label="Start's On" name="Start on"
                                       :rules="{required: true}"
                                       v-model="model.start_on" :disabled="loading"
                                       v-on:input="checkEndDate"
                                       :disabled-date="disabledDateTodayAndBefore"/>
            </div>
            <div class="col-6">
                <validated-date-picker value-type="YYYY-MM-DD" format="DD-MM-YYYY" label="End's On" name="End on" :rules="{required: true}"
                                       v-model="model.end_on" :disabled-date="disabledDateBeforeStartDate"
                                       :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-ajax-vue-select :url="trainingCenterOptions" label="Training Location"
                                           v-model="model.training_location" name="Training location"
                                           :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-date-picker value-type="YYYY-MM-DD" format="DD-MM-YYYY" name="Application end date" label="Application End Date"
                                       v-model="model.application_end_date" :disabled-date="disabledDateBeforeToday"
                                       :disabled="loading" :rules="{required: true}"/>
            </div>
            <div class="col-6">
                <validated-input label="Duration" name="Duration" v-model="model.duration"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input label="Duration Unit" name="Duration unit" v-model="model.duration_unit"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" name="Course fee" label="Course Fee" v-model="model.course_fee"
                                 :rules="{required: true}" :disabled="true"/>
            </div>
            <div class="col-6">
                <validated-input type="number" name="Seats" label="Seats" v-model="model.seats"
                                 :rules="{required: true, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" name="Tax Percentage" label="Tax Percentage"
                                 v-model="model.tax_percentage"
                                 :rules="{required: true, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" ref="file" label="Course Image" v-model="model.course_image"
                            :disabled="loading" name="Course Image">
                </file-input>
                <image-info :width="943" :height="475"/>
            </div>
            <div class="col-12">
                Course Image<br/>
                <btn type="button" class="btn-block mt-2" title="Click to view course image."
                     @click="viewFile(initialData.course_image)"/>
            </div>
            <div class="col-12 mt-2">
                <btn class="trn" text="Update" loading-text="Updating..." size="sm"
                     :disabled="loading"
                     :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name: 'EditCourse',

    props: { initialData: { type: Object } },

    data () {
        return {
            options: [
                { value: 'Started', label: 'Started' },
                { value: 'Ongoing', label: 'Ongoing' },
                { value: 'Completed', label: 'Completed' }
            ],
            addUrl: urls.admin.course.addEdit,
            trainingCenterOptions: urls.admin.trainingCenter.vueSelect
        };
    },
    watch: {
        'initialData.course_fee': {
            handler () {
                this.updateCourseFee();
            }
        }
    },
    computed: {
        checkIfPublished () {
            return this.initialData.published === true;
        }
    },
    methods: {
        updateCourseFee () {
            this.$refs.form.setModelValue('course_fee', this.initialData.course_fee);
        },
        disabledDateTodayAndBefore (date) {
            const today = new Date();
            return date < today;
        },
        checkEndDate () {
            const startOn = this.$refs.form.getModelValue('start_on');
            const endOn = this.$refs.form.getModelValue('end_on');
            const startDate = new Date(startOn);
            const endDate = new Date(endOn);
            const verifyDate = startDate >= endDate;
            if (verifyDate) {
                this.$refs.form.setModelValue('end_on', '');
            }
        },
        disabledDateBeforeStartDate (date) {
            const startOn = this.$refs.form.getModelValue('start_on');
            if (startOn) {
                const today = new Date(startOn);
                return date < today;
            }
            const today = new Date();
            return date < today;
        },
        disabledDateBeforeToday (date) {
            const today = new Date();
            today.setDate(today.getDate() - 1);
            return date < today;
        },
        clearFile (file) {
            this.initialData.banner_image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Successfully Updated Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
