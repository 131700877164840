<template>
    <vue-table-card title="Why Choose Us" :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10"
                    ref="table">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>

        <template #media="{rowData}">
            <video controls class="w-20r video-play-btn" :key="rowData.media">
                <source :src="rowData.media" type="video/mp4">
            </video>
        </template>
<!--        <template #media="{rowData}">-->
<!--            <video controls class="w-20r video-play-btn">-->
<!--                <source :src="rowData.media" type="video/mp4">-->
<!--            </video>-->
<!--        </template>-->
        <template #is_enabled="{rowData}">
            <p v-if="rowData.is_enabled">Yes</p>
            <p v-else>No</p>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
                <delete-btn @click="setDelete(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal title="Add Item" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
                <add-why-choose-us @success="formSuccess"/>
            </modal>

            <modal title="Edit Item" ref="editModal" width="40r" header-color="primary" no-close-on-backdrop>
                <edit-why-choose-us :initial-data="editingItem" @success="formSuccess"/>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the facility <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                    ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '../../../../data/urls';
import AddWhyChooseUs from './AddWhyChooseUs';
import EditWhyChooseUs from './EditWhyChooseUs';

export default {
    name: 'WhyChooseUsListing',
    components: { EditWhyChooseUs, AddWhyChooseUs },
    data () {
        return {
            listUrl: urls.admin.whyChooseUs.list,
            addUrl: urls.admin.whyChooseUs.addEdit,
            deleteUrl: urls.admin.whyChooseUs.delete,
            editingItem: null,
            deletingItem: null,
            fields: [
                {
                    name: 'order',
                    sortField: 'order',
                    title: 'Order'
                },
                {
                    name: '__slot:media',
                    title: 'Media'
                },
                {
                    name: 'created_at',
                    sortField: 'created_at',
                    title: 'Created At'
                },
                {
                    name: '__slot:is_enabled',
                    title: 'Enabled'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/admin/why-choose-us/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
            refs.editModal.close();
            refs.table.refreshTable();
            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>
