<template>
    <vue-table-card title="Course Overview" :show-search-box="false" :fields="fields"
                    :url="listUrl" :per-page="10" ref="table" :extra-params="{course: id}">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="setEdit(rowData)"/>
                <delete-btn @click="setDelete(rowData)" v-if="!checkIfPublished"/>
                <btn v-if="rowData.image" size="xs" text="View Image" @click="viewFile(rowData.image)"/>
            </div>
        </template>
        <template #type="{rowData}">
            <p v-if="rowData.type">Title</p>
            <p v-else>Image</p>
        </template>
        <template #is_enabled="{rowData}">
            <p v-if="rowData.is_enabled">Yes</p>
            <p v-else>No</p>
        </template>
<!--        <template #image="{rowData}">-->
<!--            <div class="container-image" @click="viewFile(rowData.image)">-->
<!--                <img :src="rowData.image" alt="Avatar" class="c-image">-->
<!--                <div class="c-overlay">-->
<!--                    <div class="c-icon">-->
<!--                        <i class="fa fa-eye"></i>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </template>-->

        <template #footer>
            <modal title="Course Overview" ref="addModal" width="50r" header-color="primary" no-close-on-backdrop>
                <add-course-overview @success="formSuccess" :course="id"/>
            </modal>

            <modal title="Course Overview" ref="editModal" width="50r" header-color="primary" no-close-on-backdrop>
                <edit-course-overview :checkIfPublished="checkIfPublished" :initial-data="editingItem" :course_id="id" @success="formSuccess"/>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the item <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '../../../../data/urls';
import AddCourseOverview from './AddCourseOverview';
import EditCourseOverview from './EditCourseOverview';

export default {
    name: 'CourseOverview',
    props: {
        checkIfPublished: { type: Boolean, default: false }
    },
    components: { EditCourseOverview, AddCourseOverview },
    data () {
        return {
            id: this.$route.params.id,
            listUrl: urls.admin.course.detailsUrl.overview.list,
            addUrl: urls.admin.course.detailsUrl.overview.addEdit,
            deleteUrl: urls.admin.course.detailsUrl.overview.delete,
            editingItem: null,
            deletingItem: null,
            fields: [
                // {
                //     name: '__slot:image',
                //     title: 'Image'
                // },
                {
                    name: '__slot:type',
                    title: 'Type'
                },
                {
                    name: '__slot:is_enabled',
                    title: 'Is Enabled'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.editingItem.course = this.$route.params.id;
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>
