<template>
    <vue-table-card title="Course FAQ" :show-search-box="false" :fields="fields"
                    :url="listUrl" :per-page="10" ref="table" :extra-params="{course: id}">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>
        <template #is_enabled="{rowData}">
            <p v-if="rowData.is_enabled">Yes</p>
            <p v-else>No</p>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="setEdit(rowData)"/>
                <delete-btn v-if="!checkIfPublished" @click="setDelete(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal title="Course FAQ" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
                <add-course-f-a-q @success="formSuccess" :course="id"/>
            </modal>

            <modal title="Course FAQ" ref="editModal" width="40r" header-color="primary" no-close-on-backdrop>
                <edit-course-f-a-q :checkIfPublished="checkIfPublished" :initial-data="editingItem" :course="id" @success="formSuccess"/>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the item <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '../../../../data/urls';
import AddCourseFAQ from './AddCourseFAQ';
import EditCourseFAQ from './EditCourseFAQ';

export default {
    name: 'CourseFAQ',
    props: {
        checkIfPublished: { type: Boolean, default: false }
    },
    components: { EditCourseFAQ, AddCourseFAQ },
    data () {
        return {
            id: this.$route.params.id,
            listUrl: urls.admin.course.detailsUrl.faq.list,
            addUrl: urls.admin.course.detailsUrl.faq.addEdit,
            deleteUrl: urls.admin.course.detailsUrl.faq.delete,
            editingItem: null,
            deletingItem: null,
            fields: [
                {
                    name: 'question',
                    sortField: 'question',
                    title: 'Question'
                },
                {
                    name: '__slot:is_enabled',
                    title: 'Is Enabled'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.editingItem.course = this.$route.params.id;
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>
