<template>
    <div>
        <div id="top-banner">
            <base-banner :img="[]" height="half"></base-banner>
        </div>
        <div class="container py-5">
            <inner-page-card :horz-padding="false" :link-back="true" color="basic" heading="My Courses">
                <template #default>
                    <tabs class="c-tabs-white" align="center">
                        <tab title="Registered Course">
                            <individual-registered-courses/>
                        </tab>
                        <tab title="Attended Course">
                            <individual-attended-courses/>
                        </tab>
                    </tabs>
                </template>
            </inner-page-card>
        </div>
    </div>
</template>

<script>
import InnerPageCard from '@components/home/my-training-programs/InnerPageCard';
import bannerImg from '../../../assets/web/course-offered/bgi.jpg';
import BaseBanner from '@components/ui/BaseBanner';
import IndividualRegisteredCourses from '../../../components/my-training-programs/IndividualRegisteredCourses';
import IndividualAttendedCourses from '../../../components/my-training-programs/IndividualAttendedCourses';

export default {
    name: 'MyCoursesListPage',
    components: { IndividualAttendedCourses, IndividualRegisteredCourses, InnerPageCard, BaseBanner },
    data () {
        return {
            bannerImg: bannerImg
        };
    }
};
</script>

<style scoped>

</style>
