<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
        <div class="row">
            <div class="col-12">
                <validated-text-area rows="10" label="Title" v-model="model.title" class="required-field"
                                    :rules="{required: true, max:150}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Full Description" v-model="model.full_description" class="required-field"
                                    :rules="{required: true, max:1000}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-ajax-vue-select :url="newsCategoryOptions" label="Category" class="required-field"
                                           v-model="model.category" :rules="{required: true}"
                                           :disabled="loading"/>
            </div>
            <div class="col-6">

            <validated-date-picker value-type="YYYY-MM-DD" format="DD-MM-YYYY" label="Expiry Date"
                                                       :disabled-date="disabledDateTodayAndBefore" class="required-field"
                                                       v-model="model.expiry_date" :rules="{required: true}"
                                            size="md" :disabled="loading">
                        </validated-date-picker>
                </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name: 'AddNews',

    data () {
        return {
            addUrl: urls.admin.news.addEdit,
            newsCategoryOptions: urls.masterData.NewsCategory.newsCategoryOptions
        };
    },

    methods: {
        disabledDateTodayAndBefore (date) {
            const today = new Date();
            return date < today;
        },
        formSuccess () {
            this.$notify('Successfully Added News..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
