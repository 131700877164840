<template>
    <div class="fl-x fl-x-cc">
        <div v-if="current_page !== 1" class="d-inline cursor-pointer font-lato-medium mr-2" @click="previousPage">Previous</div>
        <div>
            <btn
                v-if="current_page > 1"
                class="mr-1 mr-lg-2 border-none"
                size="sm"
                design="basic-b"
                @click="gotoPage(current_page - 1)">
                {{ current_page - 1 }}
            </btn>
            <btn
                class="mr-1 mr-lg-2 border-none active-page"
                size="sm"
                design="basic-b"
                :class="{'active-page': current_page}"
                @click="gotoPage(current_page)">
                {{ current_page }}
            </btn>
            <btn
                v-if="current_page < last_page"
                class="mr-1 mr-lg-2 border-none"
                size="sm"
                design="basic-b"
                @click="gotoPage(current_page + 1)">
                {{ current_page + 1 }}
            </btn>
        </div>
        <div v-if="current_page !== last_page" class="d-inline cursor-pointer font-inter-medium mr-2" @click="nextPage">Next</div>
    </div>
</template>

<script>
export default {
    name: 'CustomPagination',
    props: {
        lastPage: { type: [Number, String] },
        currentPage: { type: [Number, String] },
        total: { type: [Number, String] }
    },
    data () {
        return {
            current_page: Number(this.currentPage),
            last_page: Number(this.lastPage)
        };
    },
    methods: {
        nextPage () {
            if (this.current_page < this.last_page) {
                this.gotoPage(this.current_page + 1);
            }
        },
        previousPage () {
            if (this.current_page > 1) {
                this.gotoPage(this.current_page - 1);
            }
        },
        gotoPage (page) {
            this.current_page = page;
            this.$emit('gotoPage', this.current_page);
        }
    }
};
</script>

<style scoped lang="scss">
.active-page {
    background-color: var(--color-secondary);
    color: var(--color-white);
}

.pagination-btn {
    padding: var(--spacer-3) 0.8rem;
}
</style>
