<template>
    <div class="w-lg-27re">
        <div class="text-white mb-2 bg-primary fl-te-c px-3 px-4 py-32">
            <p class="m-0 fs-1">Course Fee</p>
            <p class="m-0 fs-1">&#x20B9; <span>{{ details.course.course_fee }}</span> /-</p>
        </div>
        <div class="card py-1 pb-3 bs-4 border-r-0">
            <div class="fl-te-c py-22 c-bb-1 px-1">
                <div class="fs--1">
                    <span class="text-2 fs-lg-0 c-left-item">Training Location</span>
                    <span class="mr-2 text-2">:</span>
                    <span class="font-lato-semi-bold fs-lg-0">{{ details.course.training_location_name }}</span>
                </div>
                <img src="../../assets/web/course-offered/apply-now/Training-location.png" class="icon-sm" alt="">
            </div>
            <div class="fl-te-c py-22 c-bb-1 px-1">
                <div class="fs--1">
                    <span class="text-2 fs-lg-0 c-left-item"><span>Seat Applied</span></span>
                    <span class="mr-2 text-2">:</span>
                    <span class="font-lato-medium fs-lg-0">{{ details.course.seats_applied }}</span>
                </div>
                <img src="../../assets/web/course-offered/apply-now/Seat-filled.png" class="icon-sm" alt="">
            </div>
            <div class="fl-te-c py-22 c-bb-1 px-1">
                <div class="fs--1">
                    <span class="text-2 fs-lg-0 c-left-item"><span>Seat Filled</span></span>
                    <span class="mr-2 text-2">:</span>
                    <span class="font-lato-medium fs-lg-0">{{ details.course.seats_status }}</span>
                </div>
                <img src="../../assets/web/course-offered/apply-now/Seat-filled.png" class="icon-sm" alt="">
            </div>
            <div class="fl-te-c py-22 c-bb-1 px-1" v-if="details.course.application_status==='Application Closed'">
                <div class="fs--1">
                    <span class="text-2 fs-lg-0">
                        <span>Application Closed on {{ formattedDate(details.course.application_end_date) }}</span>
                    </span>
                </div>
                <img src="../../assets/web/course-offered/apply-now/Application-closes.png" class="icon-sm" alt="">
            </div>
            <div class="fl-te-c py-22 c-bb-1 px-1" v-else>
                <div class="fs--1">
                    <span class="text-2 fs-lg-0 c-left-item"><span>Application Closes</span></span>
                    <span class="mr-2 text-2">:</span>
                    <span class="font-lato-medium fs-lg-0">{{ formattedDate(details.course.application_end_date) }}</span>
                </div>
                <img src="../../assets/web/course-offered/apply-now/Application-closes.png" class="icon-sm" alt="">
            </div>
            <div class="fl-te-c py-22 c-bb-1 px-1">
                <div class="fs--1">
                    <span class="text-2 fs-lg-0 c-left-item"><span>Course Duration</span></span>
                    <span class="mr-2 text-2">:</span>
                    <span class="font-lato-semi-bold fs-lg-0">
                        {{ details.course.duration }}
                        <span v-if="details.course.duration_unit"> {{ details.course.duration_unit }}</span>
                        <span v-else> Days</span>
                    </span>
                </div>
                <img src="../../assets/web/course-offered/apply-now/Course-duration.png" class="icon-sm" alt="">
            </div>
            <div class="fl-te-c py-22 c-bb-1 px-1">
                <div class="">
                    <span class="text-2 fs-lg-0 c-left-item"><span>Scheduled Time</span></span>
                    <span class="mr-2 text-2">:</span>
                    <span class="font-lato-semi-bold fs-lg-0">
                        {{ details.course.duration_formatted.split('(').pop().split(')')[0] }}
                    </span>
                </div>
                <img src="../../assets/web/course-offered/apply-now/Scheduled-time.png" class="icon-sm" alt="">
            </div>
            <div class="fl-te-c py-22 c-bb-1 px-1">
                <div class="fs-lg-0">
                    <span class="text-2 fs-lg-0 c-left-item"><span>Edu. Qualification</span></span>
                    <span class="mr-2 text-2">:</span>
                    <span class="font-lato-semi-bold">
                        BVSc
                    </span>
                </div>
                <img src="../../assets/web/course-offered/apply-now/Edu.-Qualification.png" class="icon-sm" alt="">
            </div>
            <div class="fl-y fl-a-c mt-4">
                <btn text="Apply Now" class="px-7 py-3" border-radius="0"
                     v-if="details.course.seats_filled!==details.course.seats &&
                     details.course.application_status!=='Application Closed' && !details.already_applied_user.applied"
                     @click="applyClicked" :loading="applyLoading" loading-text="Applying..." size="md"></btn>
                <btn v-if="details.already_applied_user.applied"
                     @click="viewDetails(details.already_applied_user.course_register.id)" size="md"
                     text="Applied Details" block class="px-5" border-radius="0"></btn>
                <div class="fl-x fl-j-c mt-1 mt-3 mb-3" @click="shareWhatsapp">
                    <span class="mr-3 font-lato-medium cursor-pointer d-sm-none">Share this Course
                        <span class="fa fa-share-alt fs--1 text-primary ml-1" style=""/>
                    </span>
                    <span class="font-lato-medium cursor-pointer ml-3 d-lg-none">Share this Courses
                        <span class="fa fa-share-alt text-primary ml-1" style="font-size:20px">
                            <ShareNetwork title="Whatsapp" class="lego-btn"
                                          network="whatsapp" style="font-size:20px" :url="getWaShareLink()"/>
                        </span>
                    </span>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'CourseInfoCard',
    computed: {
        ...mapGetters(['currentUser'])
    },
    props: {
        details: {
            type: Object, default: null
        },
        id: {
            type: String, default: ''
        },
        course_id: {
            default: ''
        }
    },
    mounted () {
        console.log('Course............................', this.course_id);
        console.log('Id............................', this.id);
    },
    data () {
        return {
            applyLoading: false
        };
    },
    methods: {
        formattedDate (dateString) {
            if (!dateString) {
                return;
            }
            const date = new Date(dateString);
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            return date.toLocaleDateString('en-GB', options);
        },
        shareWhatsapp () {
            const baseUrl = process.env.VUE_APP_BASE_URL;
            const message = baseUrl + '/course-offered/' + this.id + '/details/';
            window.open('https://web.whatsapp.com/send?text=' + message);
        },
        getWaShareLink () {
            const baseUrl = process.env.VUE_APP_BASE_URL;
            return baseUrl + '/course-offered/' + this.id + '/details/';
        },
        viewDetails (id) {
            if (this.currentUser.user_type === 'Individual') {
                this.$router.push('/course-details/' + id + '/individual/' + this.course_id);
            } else {
                this.$router.push('/course-details/' + id + '/organization/' + this.course_id);
            }
        },
        async applyClicked () {
            if (!this.currentUser.name) {
                this.$emit('notLoggedIn');
            } else {
                this.applyLoading = true;
                const response = await axios.form(urls.website.course.coursesApply, { id: this.id });
                const json = response.data;
                if (!json) {
                    this.formError(json.message);
                } else if (json.error === false) {
                    this.formSuccess(json);
                } else {
                    this.formError(json.message);
                }
                this.applyLoading = false;
            }
        },
        formSuccess (json) {
            this.$notify('Applied Successfully', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
            this.viewDetails(json.data.id);
        },
        formError (error) {
            let message = 'Could not apply, Please try again later.';
            if (error) {
                message = error;
            }
            this.$notify(message, 'Error', { type: 'danger' });
            this.$emit('error');
        }
    }
};
</script>

<style scoped lang="scss">
.c-left-item {
    display: inline-block;
    width: 8.5rem !important;
    padding-left: var(--spacer-2);
    font-family: lato-medium;
    color: rgba(141, 141, 141, 0.7) !important;
    //padding-right: var(--spacer-3);
}
</style>
