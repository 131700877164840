<template>
    <div v-if="details" class="card-item pt-7  fl-x fl-a-e " :style="{'background-image': 'url('+ img + ')'}">
        <div class="px-3 pb-4">
            <p class="mb-2 fs-2 font-weight-bold pt-3 font-jost-semi">{{ details.heading }}</p>
            <p class="fs-1 font-lato-medium">{{details.description}}</p>
        </div>
    </div>
</template>
<script>

export default {
    name: 'OurPhilosophyCard',
    components: {},
    props: {
        details: { type: Object, default: null },
        img: {
            type: String,
            default: 'null'
        }
    }
};
</script>

<style scoped>
.card-item {
    display: block;
    width: 100%;
    /*height: 100%;*/
    background-size: cover;
    text-align: center;
    color: white;
    padding: 1px;
    height: 20rem;
    background-position: center;
}

</style>
