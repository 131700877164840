<template>
    <div class="card-one pt-0 pb-0" :id="id">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 d-md-block">
                    <section-heading :title="details.name" bottom-tag-align="left" class="text-left mt-5 "
                                     align="left">
                    </section-heading>
                    <H2 class="font-jost-medium fs-5 text-center-md-res">{{ details.location }},
                        {{ details.district_name }}, {{ details.state_name }}</H2>
                </div>
                <div class="col-12 col-lg-5 order-2 order-lg-1">
                    <div class="sec-1 pt-0">

                        <section-heading :title="details.name" bottom-tag-align="left"
                                         class="text-left mt-lg-5 mt-0 d-md-none"
                                         align="left">
                        </section-heading>
                        <H2 class="font-jost-medium mt-415 mb-4 mb-lg-0 fs-5 mt-3 d-md-none ">{{ details.location }},
                            {{ details.district_name }}, {{ details.state_name }}</H2>

                        <div class="parent d-lg-none mb-3 mt-5">
                            <img class="image1 pos-r" :src="details.thumbnail1">
                            <img class="image2" :src="details.thumbnail2">
                        </div>

                        <p class="font-lato-medium fs-1 mt-4 margin-tp-md-6">{{ details.content1 }}</p>
                        <p class="font-lato-medium fs-1">{{ details.content2 }}</p>
                        <div class="mt-5">
                            <btn @click="viewLocation(details)" border-radius="0" text="View Location" class="map-icon"
                                 design="basic-b"></btn>
                            <br>
                            <btn border-radius="0" @click="$router.push('/course-offered/#' + details.id)"
                                 text="Courses Details"
                                 class="mb-5 mb-md-5 mt-3 p-btn"></btn>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-lg-7 mt-5 order-1 order-lg-2">
                    <div class="parent d-lg-content">
                        <img class="image1 pos-r" :src="details.thumbnail1">
                        <img class="image2" :src="details.thumbnail2">

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import SectionHeading from '@components/ui/SectionHeading';

export default {
    name: 'TrainingCenter02',
    props: {
        details: { type: Object, default: null },
        id: { type: String, default: '' }
    },
    components: { SectionHeading },
    methods: {
        viewLocation (item) {
            this.$emit('viewLocation', item);
        }
    }
};
</script>
<style scoped lang="scss">
    .p-btn {
        padding-left: 1rem !important;
        padding-bottom: 0.7rem !important;
        padding-top: 0.7rem !important;
        padding-right: 0.9rem !important;
    }

    .card-section {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
        color: black;
        background-position: center;
        background-size: cover;
    }

    .sec-1 {

        padding: 2rem;
        padding-left: 0 !important;
    }

    .parent {
        position: relative;
        top: 0;
        left: 0;
    }

    .image1 {
        position: relative !important;
        margin-left: -2rem;
        margin-right: -2rem;
        width: 117% !important;
        //height: 240px !important;
        @media (min-width: 768px) {
            position: absolute !important;
            top: 7px;
            right: 20px;
            width: 60% !important;
            height: 288px;
            z-index: 1;
        }
    }

    .image2 {
        position: relative;
        left: 85px;
        height: 245px;
        top: 97px;
        width: 32%;

    }

    .card-one {
        /*padding: 4rem;*/
        background-color: #F8F8F8;
        @media (min-width: 992px) {
            min-height: 85vh !important;
        }
    }

    .d-lg-none {
        display: none;
    }

    @media (max-width: 750px) {
        .d-lg-none {
            //display: contents;
            display: flex;
            text-align: center;

        }

        .d-lg-content {
            display: none;
        }

        /*.image2{*/
        /*    display: none;*/
        /*}*/
        .image1 {
            /*position: absolute;*/
            //top: 79px;
            right: 0px;
            width: 100%;
            height: 254px;
            background-position: center 100%;
            padding: 0;
            background-size: contain;

        }

        .image2 {
            /*display: none;*/
            /*position: relative;*/
            left: 0px;
            width: 0%;
            height: 255px;
            top: 0px;
            width: 0%;
            right: 0px;
            z-index: 0;
        }

        .sec-1 {
            padding: 1rem;
            text-align: center;
        }

        .card-one {
            /*padding: 1rem;*/
        }

        .p-t {
            padding-top: 2px;
        }

        .p-b {
            padding-bottom: 2px;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        .margin-tp-md-6 {
            margin-top: 8rem !important;
        }
    }
</style>
