var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-table-card',{ref:"table",attrs:{"title":"News","show-search-box":false,"fields":_vm.fields,"url":_vm.listUrl,"per-page":10},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('btn',{attrs:{"color":"success","icon":"fa fa-plus","size":"sm","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}})]},proxy:true},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[_c('btn',{attrs:{"size":"xs","text":"View"},on:{"click":function($event){return _vm.viewDetails(rowData.id)}}}),_c('delete-btn',{on:{"click":function($event){return _vm.setDelete(rowData)}}}),(!rowData.publishing_date)?_c('btn',{attrs:{"loading":_vm.loading && _vm.loading_id===rowData.id,"loading-text":"Publishing...","size":"xs","text":"Publish"},on:{"click":function($event){return _vm.publishNews(rowData.id)}}}):_c('btn',{attrs:{"loading":_vm.loading && _vm.loading_id===rowData.id,"loading-text":"Unpublishing...","size":"xs","text":"Unpublish"},on:{"click":function($event){return _vm.publishNews(rowData.id)}}})],1)]}},{key:"is_enabled",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.is_enabled)?_c('p',[_vm._v("Yes")]):_c('p',[_vm._v("No")])]}},{key:"created_at",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.created_at)?_c('p',[_vm._v(_vm._s(_vm.formattedDate(rowData.created_at)))]):_c('p')]}},{key:"publishing_date",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.publishing_date)?_c('p',[_vm._v(_vm._s(_vm.formattedDate(rowData.publishing_date)))]):_c('p',[_vm._v("Not Published")])]}},{key:"expiry_date",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.publishing_date)?_c('p',[_vm._v(_vm._s(_vm.formattedDate(rowData.expiry_date)))]):_c('p',[_vm._v("Not Published")])]}},{key:"footer",fn:function(){return [_c('modal',{ref:"addModal",attrs:{"title":"Add News","width":"40r","header-color":"primary","no-close-on-backdrop":""}},[_c('add-news',{on:{"success":_vm.formSuccess}})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the news "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". Are you sure ? ")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }