<template>
    <b-form ref="form" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">
        <div class="row">
            <div class="col-12">
                <validated-input label="Topic" v-model="model.topic"
                                 :rules="{required: true, max:100}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Theory" v-model="model.theory"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Practical" v-model="model.practical"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Description" v-model="model.description"
                                     :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <btn class="trn" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                     :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';

export default {
    name: 'EditCourseSyllabus',

    props: {
        checkIfPublished: { type: Boolean, default: false },
        initialData: { type: Object }
    },

    data () {
        return {
            addUrl: urls.admin.course.detailsUrl.syllabus.addEdit
        };
    },

    methods: {
        clearFile (file) {
            this.initialData.banner_image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Successfully Updated Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
