<template>
    <div>
        <div class="row" v-if="data">
            <div class="col-12" v-if="data.data.length!==0">
                <course-item v-for="(i, index) in data.data" :key="index" :details="i"
                             card-type="ind" class="mb-3"></course-item>
            </div>
            <div v-else class="col-12">
                <p class="text-center">You haven't registered to any Courses.</p>
            </div>
            <div class="col-12 mt-3" v-if="data.data.length!==0">
                <custom-pagination @gotoPage="refreshTable"
                                   :current-page="data.current_page"
                                   :last-page="data.last_page"
                                   :total="data.total"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import CourseItem from '../training-programs/CourseItem';
import CustomPagination from '../ui/CustomPagination';

export default {
    name: 'IndividualRegisteredCourses',
    components: { CustomPagination, CourseItem },
    data () {
        return {
            data: {},
            page: '1',
            per_page: '5',
            dataLoading: false
        };
    },

    beforeMount () {
        this.loadData();
    },

    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.course.myRegisteredCourses, {
                params: {
                    page: this.page,
                    per_page: this.per_page
                }
            });
            const json = response.data;
            if (json && json.permission === false) {
                this.$notify('Sorry, Permission denied to the requested page', 'Permission Denied', {
                    type: 'danger'
                });
                await this.$router.push('/');
            } else {
                this.data = json;
                this.dataLoading = false;
                this.$emit('loadingSuccess');
            }
        },
        refreshTable (page) {
            this.page = page;
            this.loadData();
        }
    }
};
</script>

<style scoped>
</style>
