let baseurl = process.env.BASE_URL;
const baseLen = baseurl.length;
if (baseurl[baseLen - 1] !== '/') {
    baseurl = baseurl + '/';
}

export default {
    url (path) {
        if (path[0] === '/') { path = path.substr(1); }
        return baseurl + path;
    }
};
